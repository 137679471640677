export const firebase = require("./firebase.png");
export const typescript = require("./typescript.png");
export const react = require("./react.png");
export const googleDrive = require("./googleDrive.png");
export const javascript = require("./javascript.png");
export const python = require("./python.png");
export const sql = require("./sql.png");
export const youtube = require("./youtube.png");
export const github = require("./github.png");
export const linkedin = require("./linkedin.png");
export const linkedin_transparent = require("./linkedin-transparent.png");
export const css = require("./css.png");
export const figma = require("./figma.png");
export const cv = require("./cv.png");
export const dialogflow = require("./dialogflow.png");
